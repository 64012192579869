import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/components/layout/index.jsx";
import { MathJax } from 'better-react-mathjax';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Envoirnmental Engineering and Water Supply Network`}</h1>
    <h2>{`Books`}</h2>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=0B5kjI2-xym-POFpiRkxvajB5bUE"
      }}>{`Download: Basic civil and environmental engineering by C.P. Kaushik, S.S. Bhavikatti, Anubha Kaushik`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=0B5kjI2-xym-PeDI0dlF0dDU3eTA"
      }}>{`Download: Handbook of environmental engineering calculations by C. C. Lee and Shun Dar Lin 2 Ed.`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=0B5kjI2-xym-PY0toVjFhaW5CQjA"
      }}>{`Download: Standard handbook of environmental engineering by Robert A. Corbitt 2 Ed`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=0B5kjI2-xym-PZ3NwelBLWjV0ZWc"
      }}>{`Download: Unit operations and processing in environmental engineering by Reynolds Richard 2 Ed.`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=0B5kjI2-xym-PZFhfWThVU1RKZms"
      }}>{`Download: Water and waste water engineering Design, principles and practices by Mackenzie L. Davis`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=0B5kjI2-xym-PMl9mMU5kM0hfd2M"
      }}>{`Download: Water supply and sewerage by E. W. Steel and Terence J. Macghee 5 Ed.`}</a></p>
    <h2><a parentName="h2" {...{
        "href": "https://deprecated.seismicconsolidation.com/environmental-engineering-i-lab-manual/"
      }}>{`Lab Manual`}</a></h2>
    <h2>{`Lecture slides`}</h2>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/02/Lec-1-Introduction_to_Environmental_Engg..pdf"
      }}>{`Lec-1 Introduction_to_Environmental_Engg.`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/02/Lec-2-Water_Pollution.pdf"
      }}>{`Lec-2 Water_Pollution`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/02/Lec-3-Air_Pollution_.pdf"
      }}>{`Lec-3 Air`}<em parentName="a">{`Pollution`}</em></a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/02/Lec-4-Environmental-Pollution-Issuses.pdf"
      }}>{`Lec-4 Environmental Pollution Issuses`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/02/Lec-5-Solid-Waste-Management.pdf"
      }}>{`Lec-5 Solid Waste Management`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/02/Lec-6-Noise-Pollution.pdf"
      }}>{`Lec-6 Noise Pollution`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/02/Lec-7-Water-Quality-Criteria-and-standards.pdf"
      }}>{`Lec-7 Water Quality Criteria and standards`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/02/Lec-8-Quanity-Population-forcasting.pdf"
      }}>{`Lec-8 Quantity & Population forecasting`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/02/Lec-9-Week6Water-supply-Components.pdf"
      }}>{`Lec-9-Water supply Components`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/02/Lec-10-Week7Water-Distribution-System.pdf"
      }}>{`Lec-10 Water Distribution System`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/02/Lec-11-Week9Hydraulics-of-Water-Distribution-System.pdf"
      }}>{`Lec-11 Hydraulics of Water Distribution System`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/02/Lec-12-Week9Equalivalent-pipe-concept.pdf"
      }}>{`Lec-12-Equivalent pipe concept`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/02/Lec-13-Week10Principles-of-Process-engineering.pdf"
      }}>{`Lec-13-Principles of Process engineering`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/02/Lec-14-Week10Coagulation-flocculation.pdf"
      }}>{`Lec-14-Coagulation & flocculation`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/02/Lec-15-Week12Sedmination.pdf"
      }}>{`Lec-15-Sedimentation`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/02/Lec-16-Week13Filtration1.pdf"
      }}>{`Lec-16-Filtration)`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/02/Lec-17-Week14Disinfection1.pdf"
      }}>{`Lec-17-Disinfection`}</a></p>
    <h3>{`Solution to problems`}</h3>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/02/coagulation_and_flocculation.pdf"
      }}>{`coagulation_and_flocculation`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/02/components_of_water_supply_scheme.pdf"
      }}>{`components_of_water_supply_scheme`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/02/hydraulics_of_water_distribution_system.pdf"
      }}>{`hydraulics_of_water_distribution_system`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/02/principles_of_process_engineering.pdf"
      }}>{`principles_of_process_engineering`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/02/quantity_and_population_forecasting.pdf"
      }}>{`quantity_and_population_forecasting`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/02/sedimentation.pdf"
      }}>{`sedimentation`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/02/water_distribution_system.pdf"
      }}>{`water_distribution_system`}</a></p>
    <h2><a parentName="h2" {...{
        "href": "https://deprecated.seismicconsolidation.com/download/wasa-lda-sewerage-and-water-supply-design-report/"
      }}>{`Download WASA, LDA Sewerage and water supply design report`}</a></h2>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      